import React, { useState, useEffect } from 'react';
import './RaceBetTypes.css'; // Link to your CSS file

const RaceBetTypes = ({ poolCodes, onBetTypeSelect, selectedBetType }) => {
  const [activeCode, setActiveCode] = useState(selectedBetType);

  useEffect(() => {
    setActiveCode(selectedBetType);
  }, [selectedBetType]);

  const handleButtonClick = (code) => {
    setActiveCode(code);
    onBetTypeSelect(code); // Notify parent of selected bet type
  };

  return (
    <div className="race-bet-types">
      {poolCodes.map((pool) => (
        <button
          key={pool.id}
          className={`bet-type-button ${activeCode === pool.code ? 'active' : ''}`}
          onClick={() => handleButtonClick(pool.code)}
        >
          {pool.code}
        </button>
      ))}
    </div>
  );
};

export default RaceBetTypes;
