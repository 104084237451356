import { create } from "zustand";
import axios from "axios";
import { useAuthStore } from "./useAuthStore"; // Import the auth store to access the token

const apiURL = process.env.REACT_APP_API_URL;

const useRunnersStore = create((set) => ({
  runners: [],
  raceBettingStatus: null,

  fetchRunners: async (raceId, bettingStatus) => {
    const { token } = useAuthStore.getState(); // Get the token from the auth store
    try {
      const response = await axios.get(`${apiURL}/runners/${raceId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass the token in the headers
        },
      });
      if (response.status === 200) {
        // Sort runners by numeric part of runnerNumber
        const sortedRunners = response.data.sort((a, b) => {
          const numA = parseInt(a.runnerNumber, 10);
          const numB = parseInt(b.runnerNumber, 10);
          return numA - numB;
        });
        set({ runners: sortedRunners, raceBettingStatus: bettingStatus });
      } else {
        console.error("Failed to fetch runners: ", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching runners:", error.message);
    }
  },

  setRaceBettingStatus: (status) => set({ raceBettingStatus: status }),
}));

export default useRunnersStore;
