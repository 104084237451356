import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";

const apiURL = process.env.REACT_APP_API_URL;

export const useLoadersStore = create((set) => ({
  loaders: [],

  fetchLoaders: async () => {
    const { token } = useAuthStore.getState();
    try {
      const response = await fetch(`${apiURL}/loaders`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch loaders");
      }

      const data = await response.json();
      const activeLoaders = data.filter((loader) => loader.isActive);
      set({ loaders: activeLoaders });
    } catch (error) {
      console.error("Fetch loaders error:", error.message);
    }
  },
}));
