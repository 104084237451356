import React from 'react';
import './BetButton.css'; // Create a CSS file for button styles

const BetButton = ({ label, isActive, onClick, disabled }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(!isActive); // Toggle the active state
    }
  };

  return (
    <div className={`bet-button ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`} onClick={handleClick}>
      <button disabled={disabled}>{label}</button>
    </div>
  );
};

export default BetButton;
