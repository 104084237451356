import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import TopNavigation from '../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/BalanceHeader.jsx'; // Adjust the path as necessary
import './ResultsPage.css';
import { useResultsStore } from '../../store/useResultStore';
import useEventsStore from '../../store/useEventStore'; // Import the useEventsStore

const Results = () => {
  const { raceResults, fetchRaceResults, loading, error, winRunners } = useResultsStore();
  const { races, fetchActiveEvent } = useEventsStore(); // Destructure races and fetchActiveEvent
  const isMobile = useMediaQuery('(max-width:768px)');
  const [selectedRace, setSelectedRace] = useState('Race No 1');

  useEffect(() => {
    // Fetch active event to ensure races are loaded
    fetchActiveEvent();
  }, [fetchActiveEvent]);

  useEffect(() => {
    const raceNumber = parseInt(selectedRace.split(' ')[2], 10);
    fetchRaceResults(raceNumber);
  }, [selectedRace, fetchRaceResults]);

  return (
    <>
      {isMobile ? (<Header />) : (<TopNavigation />)}
      <div className='results-content-container'>
        <div className="results-container">
          <h2>Race Results</h2>
          <div className="filter-container">
            <select value={selectedRace} onChange={(e) => setSelectedRace(e.target.value)} className="filter-dropdown">
              {races
                .slice() // Create a shallow copy of the races array
                .sort((a, b) => a.raceNumber - b.raceNumber) // Sort by raceNumber in ascending order
                .map(race => (
                  <option key={race.raceNumber} value={`Race No ${race.raceNumber}`}>
                    Race No {race.raceNumber}
                  </option>
                ))}
            </select>
          </div>

          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}

          {!loading && !error && raceResults.length === 0 && (
            <p>Results are not available yet.</p>
          )}

          {raceResults.length > 0 && (
            <table className="results-table">
              <thead>
                <tr>
                  <th>Bet Type</th>
                  <th>Winner</th>
                  <th>Dividend ₱1.00</th>
                </tr>
              </thead>
              <tbody>
                {raceResults.map((result, index) => {
                  let winners = result.winners.split(', ');
                  const raceNumber = parseInt(selectedRace.split(' ')[2], 10);

                  if (result.betType === "DAILY DOUBLE" || result.betType === "EXTRA DOUBLE") {
                    const previousWin = winRunners[raceNumber - 1];
                    const twoRacesPriorWin = winRunners[raceNumber - 2];
                    const currentWin3 = winRunners[raceNumber];
                    winners = [previousWin || "N/A", currentWin3 || "N/A"];
                    if (result.betType === "EXTRA DOUBLE" && twoRacesPriorWin) {
                      winners[0] = twoRacesPriorWin;
                    }
                  } else if (result.betType === "DAILY DOUBLE +1") {
                    const previousWin1 = winRunners[raceNumber - 1];
                    const previousWin2 = winRunners[raceNumber - 2];
                    const currentWin3 = winRunners[raceNumber];
                    winners = [previousWin2 || "N/A", previousWin1 || "N/A", currentWin3 || "N/A"];
                  } else if (result.betType === "PICK 5") {
                    winners = Array.from({ length: 5 }, (_, i) => winRunners[raceNumber - 4 + i] || "N/A");
                  } else if (result.betType === "PICK 6") {
                    winners = Array.from({ length: 6 }, (_, i) => winRunners[raceNumber - 5 + i] || "N/A");
                  } else if (result.betType === "WINNER TAKE ALL") {
                    winners = Array.from({ length: 7 }, (_, i) => winRunners[raceNumber - 6 + i] || "N/A");
                  }

                  return (
                    <tr key={index}>
                      <td>{result.betType}</td>
                      <td>
                        {winners.map((winner, idx) => (
                          <span key={idx} className="result-circle">{winner}</span>
                        ))}
                      </td>
                      <td>{result.dividend}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isMobile ? (<BottomNavigation />) : (<Footer/>)}
    </>
  );
};

export default Results;
