import React from "react";
import "./Popup.css";
import checkIcon from "../../assets/Payment/check-logo.svg";
import GradientButton from "../Button/GradientButton";

const ConfirmationPopup = ({ onClose, transactionId, amount, text }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-content">
          <div className="popup-icon">
            <img src={checkIcon} alt="Check" />
          </div>
          <p>
            <strong>Transaction ID:</strong> {transactionId}
          </p>
          <p>
            <strong>Amount:</strong> {amount}
          </p>
          <p className="popup-note">{text}</p>
          <GradientButton
            style={{ marginTop: "16px", marginBottom: "16px" }}
            text="GOT IT!"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
