import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const SelectAllCheckbox = ({ isChecked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
          style={{ color: "#ADFF2F" }}
        />
      }
      label="ALL"
      labelPlacement="end"
      style={{ 
              color: "#FFFFFF", 
              fontWeight: "bold", 
            }
        }
    />
  );
};

export default SelectAllCheckbox;
