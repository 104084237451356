import React, { useState, useEffect } from "react";
import "./BetModal.css";
import { useBetStore } from "../../../../store/useBetStore";
import useEventsStore from "../../../../store/useEventStore";
import { calculateTotalBet, isConfirmButtonDisabled } from "./betModalUtils";
import { useAuthStore } from "../../../../store/useAuthStore";
import ConfirmationPopup from "../../../../components/Pop-ups/Confirmation";
import ErrorPopup from "../../../../components/Pop-ups/ErrorPopup";

const BetModal = ({
  betInputs,
  setBetInputs,
  activeRace,
  selectedRaceRange,
  selectedBetType,
  setActiveButtons,
}) => {
  const [betAmount, setBetAmount] = useState('');
  const [multiplier, setMultiplier] = useState(1);
  const [totalBet, setTotalBet] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [betPlaced, setBetPlaced] = useState(false);
  const { placeBet } = useBetStore();
  const { poolCodes, activeEvent } = useEventsStore();
  const { user } = useAuthStore();

  useEffect(() => {
    setBetInputs({});
  }, [selectedRaceRange, setBetInputs]);

  useEffect(() => {
    if (!betPlaced) {
      const calculatedTotalBet = calculateTotalBet(betInputs, betAmount, selectedBetType, multiplier);
      console.log("Calculated Total Bet:", calculatedTotalBet); // Debug log
      setTotalBet(calculatedTotalBet);
    }
  }, [betInputs, betAmount, selectedBetType, multiplier, betPlaced]);

  const renderInputs = () => {
    const raceNumbers = selectedRaceRange ? selectedRaceRange.split(/[-&]/).map(Number) : [];
    let inputs = [];

    const calculateWidth = (inputValue) => {
      const baseWidth = 25; // Base width in pixels
      const additionalWidth = 10; // Additional width per character
      const numCharacters = inputValue.length;
      return baseWidth + (numCharacters * additionalWidth);
    };

    if (["DD+1", "WTA", "PICK5", "PICK6"].includes(selectedBetType)) {
      for (let i = raceNumbers[0]; i <= raceNumbers[raceNumbers.length - 1]; i++) {
        const inputValue = betInputs[`Race ${i}`]?.map(r => r.runnerNumber).join(', ') || '';
        inputs.push(
          <div key={i} className="bet-input-container">
            <label className="bet-input-label">Race {i}:</label>
            <input
              type="text"
              value={inputValue}
              readOnly
              className="bet-input"
              style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
            />
          </div>
        );
      }
    } else if (selectedBetType === "W") {
      const inputValue = betInputs[`Race ${activeRace}`]?.map(r => r.runnerNumber).join(', ') || '';
      inputs.push(
        <div key={activeRace} className="bet-input-container">
          <label className="bet-input-label">Race {activeRace}:</label>
          <input
            type="text"
            value={inputValue}
            readOnly
            className="bet-input"
            style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
          />
        </div>
      );
    } else if (selectedBetType === "FC") {
      const firstPlaceValue = betInputs["1st Place"]?.map(r => r.runnerNumber).join(', ') || '';
      const secondPlaceValue = betInputs["2nd Place"]?.map(r => r.runnerNumber).join(', ') || '';
      inputs.push(
        <div key={`${activeRace}-1st`} className="bet-input-container">
          <label className="bet-input-label">1st</label>
          <input
            type="text"
            value={firstPlaceValue}
            readOnly
            className="bet-input"
            style={{ width: `${calculateWidth(firstPlaceValue)}px` }} // Set dynamic width
          />
        </div>
      );
      inputs.push(
        <div key={`${activeRace}-2nd`} className="bet-input-container">
          <label className="bet-input-label">2nd </label>
          <input
            type="text"
            value={secondPlaceValue}
            readOnly
            className="bet-input"
            style={{ width: `${calculateWidth(secondPlaceValue)}px` }} // Set dynamic width
          />
        </div>
      );
    } else if (["DD", "XD"].includes(selectedBetType)) {
      raceNumbers.forEach(raceNumber => {
        const inputValue = betInputs[`Race ${raceNumber}`]?.map(r => r.runnerNumber).join(', ') || '';
        inputs.push(
          <div key={raceNumber} className="bet-input-container">
            <label className="bet-input-label">Race {raceNumber}:</label>
            <input
              type="text"
              value={inputValue}
              readOnly
              className="bet-input"
              style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
            />
          </div>
        );
      });
    }

    return inputs;
  };

  const handleConfirmBet = async () => {
    console.log("Bet Inputs:", betInputs); // Debug log
    console.log("Bet Amount:", betAmount); // Debug log
    console.log("Selected Bet Type:", selectedBetType); // Debug log
    console.log("Multiplier:", multiplier); // Debug log
    console.log("Total Bet before placing bet:", totalBet); // Debug log

    const poolId = poolCodes.find(pool => pool.code === selectedBetType)?.id;
    const combinations = [];

    const startRaceNumber = parseInt(selectedRaceRange.split('-')[0].trim());
    const raceNumbers = selectedRaceRange.split(/[^0-9]/).map(num => parseInt(num.trim())).filter(Boolean);
    const endRaceNumber = raceNumbers[raceNumbers.length - 1];

    if (selectedBetType === "W") {
        // Handle Win bet logic
        const raceBets = betInputs[`Race ${startRaceNumber}`] || [];
        const positionOneCombinations = raceBets.map(runner => ({
            position: 1,
            runnerId: runner.runnerId,
        }));
        combinations.push(positionOneCombinations);
    } else if (selectedBetType === "FC") {
        // Handle Forecast bet logic
        const firstPlaceRunners = betInputs[`1st Place`] || [];
        const secondPlaceRunners = betInputs[`2nd Place`] || [];

        // Pair runners with the same index
        const minLength = Math.min(firstPlaceRunners.length, secondPlaceRunners.length);
        for (let i = 0; i < minLength; i++) {
            const firstRunner = firstPlaceRunners[i];
            const secondRunner = secondPlaceRunners[i];
            combinations.push([
                {
                    position: 1,
                    runnerId: firstRunner.runnerId,
                },
                {
                    position: 2,
                    runnerId: secondRunner.runnerId,
                }
            ]);
        }

        // Add remaining runners from 1st place without a counterpart
        for (let i = minLength; i < firstPlaceRunners.length; i++) {
            const firstRunner = firstPlaceRunners[i];
            combinations.push([
                {
                    position: 1,
                    runnerId: firstRunner.runnerId,
                }
            ]);
        }

        // Add remaining runners from 2nd place without a counterpart
        for (let i = minLength; i < secondPlaceRunners.length; i++) {
            const secondRunner = secondPlaceRunners[i];
            combinations.push([
                {
                    position: 2,
                    runnerId: secondRunner.runnerId,
                }
            ]);
        }
    } else if (selectedBetType === "XD") {
        // Handle Exacta bet logic with two races apart
        const firstRaceBets = betInputs[`Race ${startRaceNumber}`] || [];
        const secondRaceBets = betInputs[`Race ${startRaceNumber + 2}`] || []; // Two races ahead

        const firstPositionCombinations = firstRaceBets.map(runner => ({
            position: 1,
            runnerId: runner.runnerId,
        }));

        const secondPositionCombinations = secondRaceBets.map(runner => ({
            position: 2,
            runnerId: runner.runnerId,
        }));

        if (firstPositionCombinations.length > 0) {
            combinations.push(firstPositionCombinations);
        }

        if (secondPositionCombinations.length > 0) {
            combinations.push(secondPositionCombinations);
        }
    } else {
        // Handle other bet types
        const numPositions = selectedBetType === "DD" ? 2 : selectedBetType === "PICK5" ? 5 : selectedBetType === "PICK6" ? 6 : selectedBetType === "WTA" ? 7 : 3;

        for (let i = 0; i < numPositions; i++) {
            const raceNumber = startRaceNumber + i;
            const raceBets = betInputs[`Race ${raceNumber}`] || [];
            const positionCombinations = raceBets.map(runner => ({
                position: i + 1,
                runnerId: runner.runnerId,
            }));
            combinations.push(positionCombinations);
        }
    }

    const lastRace = activeEvent.races.find(r => r.raceNumber === endRaceNumber);
    const raceId = lastRace ? lastRace.id : null;


    const initialBetAmount = selectedBetType === "DD+1" || selectedBetType === "DD" || selectedBetType === "XD" || selectedBetType === "W" || selectedBetType === "FC" ? parseFloat(betAmount) : 0;

    const betData = {
        initialBetAmount,
        pool: {
            poolId,
            poolCode: selectedBetType,
        },
        combinations,
        ...(selectedBetType === "PICK5" || selectedBetType === "PICK6" || selectedBetType === "WTA" ? { multiplier } : {})
    };

    console.log("Bet data being sent to API:", betData);
    console.log("Total Bet before placing bet:", totalBet); // Debug log

    if (raceId) {
        try {
            const response = await placeBet(raceId, betData);
            if (response.status === 201) {
                setShowConfirmationPopup(true);
                setBetPlaced(true); // Set betPlaced to true
                setBetInputs({});
                setActiveButtons({});
                console.log("Race ID:", raceId);
            } else {
                setShowErrorPopup(true);
            }
        } catch (error) {
            setShowErrorPopup(true);
        }
    } else {
        console.error("Invalid race ID");
    }
  };


  const handleCloseConfirmationPopup = () => {
    setShowConfirmationPopup(false);
    setTotalBet(0); // Reset totalBet here
    setBetPlaced(false); // Reset betPlaced here
  };

  const isButtonDisabled = () => {
    if (!user || user.balance === 0) {
      return true;
    }
    if (totalBet > (user?.balance || 0)) {
      return true;
    }
    return isConfirmButtonDisabled(selectedRaceRange, selectedBetType, betInputs, betAmount);
  };

  return (
    <div className="bet-modal">
      <h2 className="bet-modal-title">Place Your Bet</h2>
      <div className="bet-inputs">{renderInputs()}</div>
      {!(selectedBetType === "PICK5" || selectedBetType === "PICK6" || selectedBetType === "WTA") && (
        <input
          className="bet-amount-input"
          type="number"
          placeholder="Enter Bet Amount"
          value={betAmount}
          onChange={(e) => setBetAmount(e.target.value)}
        />
      )}
      {(selectedBetType === "PICK5" || selectedBetType === "PICK6" || selectedBetType === "WTA") && (
        <input
          className="multiplier-input"
          type="number"
          placeholder="Enter Multiplier"
          value={multiplier}
          onChange={(e) => setMultiplier(parseInt(e.target.value) || 1)}
        />
      )}
      <button
        className={`confirm-modal-button ${isButtonDisabled() ? 'disabled' : ''}`}
        onClick={handleConfirmBet}
        disabled={isButtonDisabled()}
      >
        Confirm Bet - {totalBet.toFixed(2)}
      </button>
      {totalBet > (user?.balance || 0) && (
        <div className="insufficient-balance-note">
          Insufficient balance to place this bet.
        </div>
      )}

      {showConfirmationPopup && (
        <ConfirmationPopup
          onClose={handleCloseConfirmationPopup}
          transactionId="123456"
          amount={totalBet}
          text="You have bet successfully"
        />
      )}
      {showErrorPopup && (
        <ErrorPopup onClose={() => setShowErrorPopup(false)} />
      )}
    </div>
  );
};

export default BetModal;
