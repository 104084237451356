// src/components/RaceEvent/RaceVideoSection.jsx
import React from "react";
import useEventsStore from "../../../../store/useEventStore"; // Import the store

const RaceVideoSection = () => {
  const { videoLink } = useEventsStore(); // Get the video link from the store

  return (
    <div className="race-video-section">
      <iframe
        className="race-video"
        width="100%"
        height="200"
        src={videoLink} // Use the video link from the store
        title="Race Video Stream"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default RaceVideoSection;
