export const calculateTotalBet = (betInputs, betAmount, selectedBetType, multiplier = 1) => {
  let total = 0;
  const betPerCombination = parseFloat(betAmount) || 0;

  const getUniqueRunnersCount = (runners) => {
    const uniqueNumbers = new Set();
    runners.forEach(runner => {
      const numericPart = runner.runnerNumber.match(/\d+/)[0];
      uniqueNumbers.add(numericPart);
    });
    return uniqueNumbers.size;
  };

  switch (selectedBetType) {
    case "FC":
      const firstPlaceBets = betInputs["1st Place"] || [];
      const secondPlaceBets = betInputs["2nd Place"] || [];
      const numWinning = getUniqueRunnersCount(firstPlaceBets);
      const numSecondPlace = getUniqueRunnersCount(secondPlaceBets);
      const identicalPredictions = firstPlaceBets.filter(first =>
        secondPlaceBets.some(second => second.runnerId === first.runnerId)
      ).length;
      total += betPerCombination * ((numWinning * numSecondPlace) - identicalPredictions);
      break;
    case "DD":
    case "XD":
      const races = Object.keys(betInputs);
      if (races.length >= 2) {
        const firstRaceBets = betInputs[races[0]] || [];
        const secondRaceBets = betInputs[races[1]] || [];
        const numFirstRace = getUniqueRunnersCount(firstRaceBets);
        const numSecondRace = getUniqueRunnersCount(secondRaceBets);
        total += betPerCombination * (numFirstRace * numSecondRace);
      }
      break;
    case "PICK5":
    case "PICK6":
    case "WTA":
      const raceCounts = Object.values(betInputs).map(runners => getUniqueRunnersCount(runners));
      total = 2 * raceCounts.reduce((acc, count) => acc * count, 1) * multiplier;
      break;
    case "DD+1":
      const [n1 = 0, n2 = 0, n3 = 0] = Object.values(betInputs).map(runners => getUniqueRunnersCount(runners));
      total = betPerCombination * n1 * n2 * n3;
      break;
    case "W":
      const numBets = Object.values(betInputs).reduce((acc, runners) => acc + getUniqueRunnersCount(runners), 0);
      total += betPerCombination * numBets;
      break;
    default:
      break;
  }

  return total;
};

export const isConfirmButtonDisabled = (selectedRaceRange, selectedBetType, betInputs, betAmount) => {
  if (!selectedRaceRange) {
    return true; // Disable if selectedRaceRange is null or undefined
  }

  switch (selectedBetType) {
    case "W":
      return !betAmount || Object.values(betInputs).every(runners => runners.length === 0);
    case "FC":
      return !betAmount || !betInputs["1st Place"]?.length || !betInputs["2nd Place"]?.length;
    case "DD":
    case "XD":
      return !betAmount || Object.keys(betInputs).length < 2 || Object.values(betInputs).some(runners => runners.length === 0);
    case "DD+1":
      return !betAmount || Object.keys(betInputs).length < 3 || Object.values(betInputs).some(runners => runners.length === 0);
    case "PICK5":
    case "PICK6":
    case "WTA":
      const raceNumbers = selectedRaceRange.split(/[-&]/).map(Number);
      return raceNumbers.some(raceNumber => !betInputs[`Race ${raceNumber}`]?.length);
    default:
      return true;
  }
};
