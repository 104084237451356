import React from 'react';
import './Load.css';
import loadLogo from "../../assets/Loading/kk-loading.svg";

const LoadingPage = () => {
  return (
    <div className="loading-container">
      <div className="loading-logo">
        <img src={loadLogo} alt="Karera King Logo" />
      </div>
      <div className="progress-bar">
        <div className="progress"></div>
      </div>
      <p className="loading-text">LOADING....</p>
      <footer className="footer">
        Copyright 2024. www.KareraKing.com All Rights Reserved.
      </footer>
    </div>
  );
};

export default LoadingPage;
