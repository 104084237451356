import { io } from "socket.io-client";

export const socket = io(
  process.env.REACT_APP_SOCKET_SERVER || "http://localhost:3001",
  {
    transports: ["websocket"],
    query: {
      token: localStorage.getItem("token"),
    },
  }
);
export const SocketEvents = {
  bet: {
    open: "open",
    lastCall: "last call",
    closed: "closed",
    active: "active",
    suspended: "suspended",
  },
};
