import React, { useEffect } from "react";
import "../Profile.css";
import { useAuthStore } from "../../../../store/useAuthStore";

const ProfileTab = () => {
  const { fetchUserProfile, user } = useAuthStore((state) => ({
    logout: state.logout,
    fetchUserProfile: state.fetchUserProfile,
    user: state.user,
  }));

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  return (
    <div className="profile-form-container">
      <div className="profile-form">
        <div className="input-group">
          <label htmlFor="player-id">Player ID</label>
          <input
            type="text"
            id="player-id"
            value={user ? `${user.partyId}` : "Loading..."}
            disabled // Always disabled
          />
        </div>
        <div className="input-group">
          <label htmlFor="full-name">Full Name</label>
          <input
            type="text"
            id="full-name"
            value={user ? `${user.firstName} ${user.lastName}` : "Loading..."}
            disabled // Always disabled
          />
        </div>
        <div className="input-group">
          <label htmlFor="mobile-number">Mobile Number</label>
          <input
            type="text"
            id="mobile-number"
            value={user ? `${user.mobileNumber}` : "Loading..."}
            disabled // Always disabled
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
