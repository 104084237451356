import React, { useState, useEffect } from "react";
import "./RaceTab.css";

const RaceTab = ({ selectedRaceRange, onRaceSelect }) => {
  const [activeRace, setActiveRace] = useState(null);

  useEffect(() => {
    if (selectedRaceRange) {
      const firstRaceNumber = getFirstRaceNumber(selectedRaceRange);
      setActiveRace(firstRaceNumber);
      onRaceSelect(firstRaceNumber); // Notify parent of the default selected race
    }
  }, [selectedRaceRange, onRaceSelect]);

  const getFirstRaceNumber = (range) => {
    if (range.includes('&')) {
      return parseInt(range.split('&')[0], 10);
    } else if (!range.includes('-')) {
      return parseInt(range, 10);
    } else {
      return parseInt(range.split(/[-&]/)[0], 10);
    }
  };

  const handleRaceSelect = (raceNumber) => {
    setActiveRace(raceNumber);
    onRaceSelect(raceNumber); // Notify parent of the selected race
  };

  const renderRaceButtons = () => {
    if (!selectedRaceRange) return null;

    const raceButtons = [];

    if (selectedRaceRange.includes('&')) {
      const [start, end] = selectedRaceRange.split('&').map(Number);

      raceButtons.push(
        <button
          key={start}
          className={`race-button ${activeRace === start ? "active" : ""}`}
          onClick={() => handleRaceSelect(start)}
        >
          Race {start}
        </button>
      );
      raceButtons.push(
        <button
          key={end}
          className={`race-button ${activeRace === end ? "active" : ""}`}
          onClick={() => handleRaceSelect(end)}
        >
          Race {end}
        </button>
      );
    } else if (!selectedRaceRange.includes('-')) {
      const raceNumber = parseInt(selectedRaceRange, 10);
      raceButtons.push(
        <button
          key={raceNumber}
          className={`race-button ${activeRace === raceNumber ? "active" : ""}`}
          onClick={() => handleRaceSelect(raceNumber)}
        >
          Race {raceNumber}
        </button>
      );
    } else {
      const [start, end] = selectedRaceRange.split(/[-&]/).map(Number);
      for (let i = start; i <= end; i++) {
        raceButtons.push(
          <button
            key={i}
            className={`race-button ${activeRace === i ? "active" : ""}`}
            onClick={() => handleRaceSelect(i)}
          >
            Race {i}
          </button>
        );
      }
    }

    return raceButtons;
  };

  return (
    <div className="race-result-wrapper">
      <div className="race-tabs-container">
        {/* Render race-specific content */}
        <div className="race-content">
        </div>
      </div>

      {/* Display race buttons */}
      <div className="race-buttons-container">
        {renderRaceButtons()}
      </div>
    </div>
  );
};

export default RaceTab;
