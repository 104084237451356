import React, { useEffect, useState, useCallback } from "react";
import "./RaceNumber.css";

const RaceNumber = ({ selectedBetType, expectedRaces, onRaceRangeSelect }) => {
  const [raceNumbers, setRaceNumbers] = useState([]);
  const [activeRace, setActiveRace] = useState(null);

  const handleRaceRangeSelect = useCallback(
    (race) => {
      onRaceRangeSelect(race);
    },
    [onRaceRangeSelect]
  );

  const generateRaceNumbers = useCallback((betType, expected_race) => {
    switch (betType) {
      case "DD":
        return Array.from(
          { length: expected_race - 1 },
          (_, i) => `${i + 1} & ${i + 2}`
        );
      case "FC":
      case "W":
        return Array.from({ length: expected_race }, (_, i) =>
          (i + 1).toString()
        );
      case "XD":
        return Array.from(
          { length: expected_race - 2 },
          (_, i) => `${i + 1} & ${i + 3}`
        );
      case "DD+1":
        return Array.from(
          { length: expected_race - 2 },
          (_, i) => `${i + 1} - ${i + 3}`
        );
      case "PICK5":
        return getPick5Combinations(expected_race);
      case "PICK6":
        return getPick6Combinations(expected_race);
      case "WTA":
        return getWTACombinations(expected_race);
      default:
        return [];
    }
  }, []);

  const getPick5Combinations = (expected_race) => {
    if (expected_race === 7) return [`${expected_race - 4} - ${expected_race}`];
    if (expected_race === 8) return [`1 - 5`, `4 - 8`];
    if (expected_race === 9) return [`2 - 6`, `5 - 9`];
    if (expected_race === 10) return [`1 - 5`, `3 - 7`, `5 - 10`];
    if (expected_race === 12) return [`2 - 6`, `5 - 9`, `8 - 12`];
    if (expected_race === 13) return [`3 - 7`, `6 - 10`, `9 - 13`];
    return [];
  };

  const getPick6Combinations = (expected_race) => {
    if (expected_race === 7) return [`2 - 7`];
    if (expected_race === 8) return [`3 - 8`];
    if (expected_race === 9) return [`4 - 9`];
    if (expected_race === 10) return [`2 - 7`, `5 - 10`];
    if (expected_race === 12) return [`3 - 8`, `7 - 12`];
    if (expected_race === 13) return [`2 - 7`, `6 - 10`, `9 - 13`];
    return [];
  };

  const getWTACombinations = (expected_race) => {
    if (expected_race === 7) return [`1 - 7`];
    if (expected_race === 8) return [`2 - 8`];
    if (expected_race === 9) return [`1 - 7`, `3 - 9`];
    if (expected_race === 10) return [`4 - 10`];
    if (expected_race === 12) return [`1 - 7`, `4 - 10`, `6 - 12`];
    if (expected_race === 13) return [`1 - 7`, `4 - 10`, `7 - 13`];
    return [];
  };

  useEffect(() => {
    if (selectedBetType && expectedRaces) {
      const numbers = generateRaceNumbers(selectedBetType, expectedRaces);
      setRaceNumbers(numbers);
      if (numbers.length > 0) {
        setActiveRace(numbers[0]);
        handleRaceRangeSelect(numbers[0]);
      }
    }
  }, [
    selectedBetType,
    expectedRaces,
    generateRaceNumbers,
    handleRaceRangeSelect,
  ]);

  return (
    <div className="race-number-buttons">
      {raceNumbers.map((race, index) => (
        <button
          key={index}
          className={`race-number-button ${
            activeRace === race ? "active" : ""
          }`}
          onClick={() => {
            setActiveRace(race);
            onRaceRangeSelect(race);
          }}
        >
          {race}
        </button>
      ))}
    </div>
  );
};

export default RaceNumber;
