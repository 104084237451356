import "../PaymentPage.css";
import React, { useState } from "react";
import gcash from "../../../assets/Withdraw/gcash.svg";
import maya from "../../../assets/Withdraw/maya.svg";
import bpi from "../../../assets/Withdraw/bpi.svg";
import bdo from "../../../assets/Withdraw/bdo.svg";
import chinabank from "../../../assets/Withdraw/chinabank.svg";
import metrobank from "../../../assets/Withdraw/metrobank.svg";
import unionbank from "../../../assets/Withdraw/unionbank.svg";
import GradientButton from "../../../components/Button/GradientButton";
import ConfirmationPopup from "../../../components/Pop-ups/Confirmation";
import { useWalletStore } from "../../../store/useWalletStore";
import ErrorPopup from "../../../components/Pop-ups/ErrorPopup";

const Withdraw = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("gcash");
  const [transactionId, setTransactionId] = useState("8455936");
  const [amount, setAmount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [remarks, setRemarks] = useState("");

  const { cashOut } = useWalletStore();

  const methodLabels = {
    gcash: { nameLabel: "Gcash Name", numberLabel: "Gcash Number" },
    bdo: { nameLabel: "BDO Account Name", numberLabel: "BDO Account Number" },
    bpi: { nameLabel: "BPI Account Name", numberLabel: "BPI Account Number" },
    maya: { nameLabel: "Maya Name", numberLabel: "Maya Number" },
    metrobank: {
      nameLabel: "Metrobank Account Name",
      numberLabel: "Metrobank Account Number",
    },
    unionbank: {
      nameLabel: "UnionBank Account Name",
      numberLabel: "UnionBank Account Number",
    },
    chinabank: {
      nameLabel: "China Bank Account Name",
      numberLabel: "China Bank Account Number",
    },
  };

  const handleMethodClick = (method) => {
    setSelectedMethod(method);
  };

  const validateInputs = () => {
    if (!selectedMethod) return false;
    if (!/^\d+(\.\d+)?$/.test(amount)) return false;
    if (parseFloat(amount) < 200 || parseFloat(amount) > 30000) return false;
    if (!/^[a-zA-Z\s]+$/.test(accountName)) return false;

    const numberLength = accountNumber.length;
    if (selectedMethod === "gcash" || selectedMethod === "maya") {
      if (numberLength !== 11) return false;
    } else {
      if (numberLength < 8 || numberLength > 14) return false;
    }

    return true;
  };

  const handleWithdrawClick = async () => {
    if (validateInputs()) {
      try {
        const response = await cashOut(
          parseFloat(amount),
          selectedMethod,
          accountNumber,
          accountName,
          remarks
        );
        console.log("Cash-out response:", response);
        setTransactionId(response.id);
        setShowPopup(true);
      } catch (error) {
        console.error("Cash-out error:", error.message);
        setShowErrorPopup(true);
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const { nameLabel, numberLabel } = selectedMethod
    ? methodLabels[selectedMethod]
    : { nameLabel: "Account Name", numberLabel: "Account Number" };

  const isButtonDisabled = !validateInputs();

  return (
    <div className="payment-method-container">
      <h4 className="section-label">Withdrawal Methods</h4>
      <span className="withdrawal-note">
        NOTE: Please be aware that a ₱15 fee applies to all withdrawal methods
        except for GCash.
      </span>
      <div className="withdraw-details-container">
        <div className="payment-method-icons">
          <img
            src={gcash}
            alt="GCash"
            className={selectedMethod === "gcash" ? "selected" : ""}
            onClick={() => handleMethodClick("gcash")}
          />
          <img
            src={bdo}
            alt="BDO"
            className={selectedMethod === "bdo" ? "selected" : ""}
            onClick={() => handleMethodClick("bdo")}
          />
          <img
            src={bpi}
            alt="BPI"
            className={selectedMethod === "bpi" ? "selected" : ""}
            onClick={() => handleMethodClick("bpi")}
          />
          <img
            src={maya}
            alt="Maya"
            className={selectedMethod === "maya" ? "selected" : ""}
            onClick={() => handleMethodClick("maya")}
          />
          <img
            src={metrobank}
            alt="MetroBank"
            className={selectedMethod === "metrobank" ? "selected" : ""}
            onClick={() => handleMethodClick("metrobank")}
          />
          <img
            src={unionbank}
            alt="UnionBank"
            className={selectedMethod === "unionbank" ? "selected" : ""}
            onClick={() => handleMethodClick("unionbank")}
          />
          <img
            src={chinabank}
            alt="ChinaBank"
            className={selectedMethod === "chinabank" ? "selected" : ""}
            onClick={() => handleMethodClick("chinabank")}
          />
        </div>
        <div className="payment-details">
          <div className="input-group">
            <label htmlFor="amount">Withdraw Amount</label>
            <input
              type="text"
              id="withdraw-amount"
              placeholder="₱0.00"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            {amount && !/^\d+(\.\d+)?$/.test(amount) && (
              <span>Please input a numeric value.</span>
            )}
            {amount && parseFloat(amount) < 200 && (
              <span className="warning-message">Minimum withdrawal amount is ₱199.99</span>
            )}
            {amount && parseFloat(amount) > 30000 && (
              <span className="warning-message">Maximum withdrawal amount is ₱30,000</span>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="account-name">{nameLabel}</label>
            <input
              type="text"
              id="account-name"
              placeholder="Juan Dela Cruz"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
            {accountName && !/^[a-zA-Z\s]+$/.test(accountName) && (
              <span className="warning-message">Please provide a text input.</span>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="account-number">{numberLabel}</label>
            <input
              type="text"
              id="account-number"
              placeholder="XXXXXXXXXX"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            {selectedMethod && accountNumber && (
              <span className="warning-message">
                {selectedMethod === "gcash" || selectedMethod === "maya"
                  ? accountNumber.length !== 11 &&
                    "Account number must be exactly 11 digits."
                  : (accountNumber.length < 8 || accountNumber.length > 14) &&
                    "Input must be at least 8 digits and no more than 14 digits."}
              </span>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="note">Note</label>
            <textarea
              id="note"
              placeholder="Enter note"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <GradientButton
        style={{
          marginTop: "8px",
          marginBottom: "16px",
          background: isButtonDisabled
            ? "#5B5B5B"
            : "linear-gradient(90deg, #FFF61F 27%, #7BF342 100%)",
          color: isButtonDisabled ? "#9F9F9F" : undefined,
          cursor: isButtonDisabled ? "not-allowed" : "pointer",
        }}
        text="WITHDRAW NOW"
        onClick={handleWithdrawClick}
        disabled={isButtonDisabled}
      />

      {showPopup && (
        <ConfirmationPopup
          onClose={handleClosePopup}
          transactionId={transactionId}
          amount={amount}
          text="Note: Please wait for 5 minute upon withdrawal"
        />
      )}
      {showErrorPopup && (
        <ErrorPopup 
            onClose={handleCloseErrorPopup} 
            errorMessage="I'm sorry, but your balance isn't enough to complete the withdrawal."
        />
      )}
    </div>
  );
};

export default Withdraw;
