import React, { useState, useEffect, useRef } from "react";
import "../Transaction.css";
import { useWalletStore } from "../../../store/useWalletStore";

const Wallet = () => {
  const { transactions, fetchTransactions } = useWalletStore();
  const [filterType, setFilterType] = useState("All");
  const [filterStatus, setFilterStatus] = useState("All");
  const listRef = useRef(null);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [transactions]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const filteredTransactions = (transactions || [])
    .map((transaction) => ({
      ...transaction,
      date: formatDate(transaction.updatedAt),
      type: transaction.type === "credit" ? "Deposit" : "Withdrawal",
      status:
        transaction.status === "approved"
          ? "Approved"
          : transaction.status === "pending"
          ? "Pending"
          : "Declined",
    }))
    .filter((transaction) => {
      return (
        (filterType === "All" || transaction.type === filterType) &&
        (filterStatus === "All" || transaction.status === filterStatus)
      );
    });

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#00FF00"; // Blue
      case "Pending":
        return "#FFDF5A"; // Yellow
      case "Declined":
        return "#A2A2A2"; // Gray
      default:
        return "inherit";
    }
  };

  return (
    <div className="transaction-tab-container">
      <div className="filter-container">
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All Types</option>
          <option value="Deposit">Deposit</option>
          <option value="Withdrawal">Withdrawal</option>
        </select>

        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All Status</option>
          <option value="Approved">Approved</option>
          <option value="Pending">Pending</option>
          <option value="Declined">Declined</option>
        </select>
      </div>

      <div className="transaction-list" ref={listRef}>
        {filteredTransactions.map((transaction, index) => (
          <div key={index} className="transaction-item">
            <div className="transaction-header">
              <span className="transaction-date">{transaction.date}</span>
              <span className="transaction-status">
                <span className="status-label">Status: </span>
                <span className="status-value" style={{ color: getStatusColor(transaction.status) }}>{transaction.status}</span>
              </span>
            </div>
            <div className="transac-id"> <strong>ID:</strong> {transaction.id}</div>
            <div className="transaction-details">
              <div className="transaction-details-left">
                {transaction.type === "Deposit" ? (
                  <>
                    <div className="from-gcash">From Gcash:</div>
                    <ul>
                      <li className="transac-details-item">{transaction.metadata?.accountNumber || "N/A"}</li>
                      <li className="transac-details-item">Reference Number: {transaction.metadata?.referenceNumber || "N/A"}</li>
                    </ul>
                  </>
                ) : (
                  <>
                    <div className="sent-to">Sent to:</div>
                    <ul>
                      <li className="transac-details-item">{transaction.metadata?.accountNumber || "N/A"}</li>
                      <li className="transac-details-item"> Account Name: {transaction.metadata?.accountName || "N/A"}</li>
                    </ul>
                  </>
                )}
              </div>
              <div className="transaction-details-right">
                <div className="transac-type">{transaction.type}</div>
              </div>
              <> 
                <div className="remarks-label">Remarks:</div> 
                <ul>
                <li className="transac-details-item">{transaction.metadata?.remarks || "N/A"}</li>
                </ul>
              </>
              <div className="transac-amount">Amount: <strong>₱{transaction.amount.toFixed(2)}</strong></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wallet;
