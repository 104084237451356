import React from "react";

const Winner = ({ place, number }) => (
  <div style={styles.winnerBadge}>
    <span style={styles.winnerText}>
      {place} - #{number}
    </span>
  </div>
);

const styles = {
  winnerBadge: {
    marginLeft: "-8px",
    marginBottom: "10px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #ADFF2F 27%, #7BF342 100%)",
    borderRadius: "20px",
    padding: "4px 12px",
    color: "black",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  winnerText: {
    fontFamily: "Arial, sans-serif",
    fontWeight: "bold",
    color: "#000000",
  },
};

export default Winner;
