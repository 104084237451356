import React, { useRef, useEffect, useMemo } from "react";
import "../Transaction.css";
import { CurrencyFormatter } from "../../../formatter/currencyFormatter";

const Bets = () => {
  const transactions = useMemo(() => [
    {
      date: "Sept 11 10:15 PM",
      ticketNo: "726272",
      status: "WIN",
      betType: "Double R1 - R2",
      bets: [
        { race: "R1", numbers: [1, 2, 3] },
        { race: "R2", numbers: [3, 5, 6, 7] },
      ],
      betAmount: "P10 x 2",
      totalBet: "P20",
      amount: "P1,000,000",
    },
    {
      date: "Sept 11 10:15 PM",
      ticketNo: "726273",
      status: "LOSE",
      betType: "Double R1 - R2",
      bets: [
        { race: "R1", numbers: [1, 2, 3] },
        { race: "R2", numbers: [3, 5, 6, 7, 8, 9, 10] },
      ],
      betAmount: "P10 x 2",
      totalBet: "P20",
      amount: "-P10,000",
    },
    {
      date: "Sept 11 10:15 PM",
      ticketNo: "726274",
      status: "REFUND",
      betType: "Double R1 - R2",
      bets: [
        { race: "R1", numbers: [1, 2, 3] },
        { race: "R2", numbers: [3, 5, 6, 7] },
      ],
      betAmount: "P10 x 2",
      totalBet: "P20",
      amount: "P10,000",
    },
  ], []);

  const betListRef = useRef(null);

  useEffect(() => {
    if (betListRef.current) {
      betListRef.current.scrollTop = betListRef.current.scrollHeight;
    }
  }, [transactions]);

  return (
    <div className="bet-container" ref={betListRef}>
      {transactions.map((transaction, index) => (
        <div key={index} className="bet-transaction">
          <div className="bet-header">
            <span className="bet-date">{transaction.date}</span>
            <span className="bet-ticket">
              Ticket No. <strong>{transaction.ticketNo}</strong>
            </span>
            <span className={`bet-status ${transaction.status.toLowerCase()}`}>
              {transaction.status}
            </span>
          </div>
          <table className="bet-table">
            <thead>
              <tr>
                <th>Bet Type</th>
                <th>Bets</th>
                <th>Bet Amount</th>
                <th>Total Bet</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{transaction.betType}</td>
                <td>
                  {transaction.bets.map((bet, i) => (
                    <div key={i}>
                      <strong>{bet.race}:</strong>
                      <div className="bet-line">
                        {bet.numbers.map((num, j) => (
                          <div key={j} className="bet-circle">
                            {num}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </td>
                <td>{transaction.betAmount}</td>
                <td>{transaction.totalBet}</td>
              </tr>
            </tbody>
          </table>
          <div className="bet-amount">
            Amount: <strong>
              <CurrencyFormatter value={parseFloat(transaction.amount.replace(/[^0-9.-]+/g, ''))} />
            </strong>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Bets;
