import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

export const useBetStore = create((set) => ({
  placeBet: async (raceId, betData) => {
    const { token } = useAuthStore.getState();
    try {
      const response = await axios.post(`${apiURL}/bet/${raceId}`, betData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response; // Return the response object
    } catch (error) {
      console.error("Error placing bet:", error.message);
      throw error; // Throw the error to be caught in BetModal
    }
  },
}));
