import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./AccountPage.css";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/BalanceHeader.jsx";
import profileIcon from "../../assets/PlayerAccount/profile-icon.svg";
import bettingRulesIcon from "../../assets/PlayerAccount/betting-rules-icon.svg";
import aboutIcon from "../../assets/PlayerAccount/about-icon.svg";
import privacyPolicyIcon from "../../assets/PlayerAccount/privacy-policy-icon.svg";
import termsConditionsIcon from "../../assets/PlayerAccount/terms-conditions-icon.svg";
import signOutIcon from "../../assets/PlayerAccount/sign-out-icon.svg";
import backgroundImage from "../../assets/PlayerAccount/bgHeader.svg";
import { useAuthStore } from "../../store/useAuthStore"; // Import useAuthStore

const AccountPage = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate(); // Initialize navigation

  // Navigation handlers
  const goToProfile = () => navigate("/account/profile");
  const goToBettingRules = () => navigate("/account/betting-rules");
  const goToAbout = () => navigate("/account/about");
  const goToPrivacyPolicy = () => navigate("/account/private-policy");
  const goToTermsConditions = () => navigate("/account/terms-condition");
  const { logout, fetchUserProfile, user } = useAuthStore((state) => ({
    logout: state.logout,
    fetchUserProfile: state.fetchUserProfile,
    user: state.user,
  }));

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  // Logout handler
  const handleSignOut = () => {
    logout(); // Call logout from useAuthStore
    navigate("/login"); // Redirect to the login page
  };

  // Extract initials
  const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return "N/A"; // Default initials
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  return (
    <div className="account-page-container">
      {isMobile ? <Header /> : <TopNavigation />}
      <div className="account-page">
        {/* Background image container */}
        <div className="account-background-container">
          <img
            src={backgroundImage}
            alt="Background"
            className="account-background"
          />
          <div className="account-header">
            <div className="account-avatar">
              <span className="avatar-initials">
                {user ? getInitials(user.firstName, user.lastName) : "AP"}
              </span>{" "}
              {/* Avatar initials */}
            </div>
            <div className="account-info">
              <h2 className="account-name">{user ? `${user.firstName} ${user.lastName}` : "Loading..."}</h2>
              <p className="account-phone">{user ? `${user.mobileNumber}` : "Loading..."}</p>
            </div>
          </div>
        </div>

        <div className="account-options">
          <div className="account-option" onClick={goToProfile}>
            <img src={profileIcon} alt="Profile" />
            <span>Profile</span>
            <span className="arrow">›</span>
          </div>
          <div className="account-option" onClick={goToBettingRules}>
            <img src={bettingRulesIcon} alt="Betting Rules" />
            <span>Betting Rules</span>
            <span className="arrow">›</span>
          </div>
          <div className="account-option" onClick={goToAbout}>
            <img src={aboutIcon} alt="About" />
            <span>About</span>
            <span className="arrow">›</span>
          </div>
          <div className="account-option" onClick={goToPrivacyPolicy}>
            <img src={privacyPolicyIcon} alt="Privacy Policy" />
            <span>Privacy Policy</span>
            <span className="arrow">›</span>
          </div>
          <div className="account-option" onClick={goToTermsConditions}>
            <img src={termsConditionsIcon} alt="Terms & Conditions" />
            <span>Terms & Conditions</span>
            <span className="arrow">›</span>
          </div>
          <div className="account-option sign-out" onClick={handleSignOut}>
            {" "}
            {/* Add onClick handler */}
            <img src={signOutIcon} alt="Sign Out" />
            <span>SIGN OUT</span>
          </div>
        </div>
      </div>
      {isMobile ? <BottomNavigation /> : <Footer />}
    </div>
  );
};

export default AccountPage;
