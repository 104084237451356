import React from "react";
import "./RaceDetails.css";

const RaceDetails = ({ arenaName, eventName }) => {
  return (
    <div className="race-details-container">
      <div className="race-details">
        <p className="race-details-text first">
          MANILA JOCKEY CLUB, INC. - 2021 PHILRACOM RATING BASED HANDICAPPING
          SYSTEM {eventName}
        </p>
        <p className="race-details-text second">{arenaName}</p>
      </div>
    </div>
  );
};

export default RaceDetails;
