import "../PaymentPage.css";
import React, { useState, useEffect } from "react";
import ConfirmationPopup from "../../../components/Pop-ups/Confirmation";
import GradientButton from "../../../components/Button/GradientButton";
import gcash from "../../../assets/Withdraw/gcash.svg";
import maya from "../../../assets/Withdraw/maya.svg";
import bank from "../../../assets/Withdraw/bank.svg";
import gotyme from "../../../assets/Withdraw/gotyme.svg";
import paygo from "../../../assets/Withdraw/paygo.svg";
import cliqq from "../../../assets/Withdraw/711.svg";
import checkIcon from "../../../assets/Deposit/check.svg";
import copyIcon from "../../../assets/Deposit/copyyy.svg";
import CopyPopup from "../../../components/Pop-ups/CopyPopup";
import { useWalletStore } from "../../../store/useWalletStore";
import { useLoadersStore } from "../../../store/useLoadersStore";
import ErrorPopup from "../../../components/Pop-ups/ErrorPopup";

const Deposit = () => {
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [senderNumber, setSenderNumber] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState("");
  const [senderError, setSenderError] = useState("");
  const [referenceError, setReferenceError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState("gcash");

  const { cashIn } = useWalletStore();
  const { loaders, fetchLoaders } = useLoadersStore();

  useEffect(() => {
    fetchLoaders();
  }, [fetchLoaders]);

  const methodLabels = {
    gcash: {
      label: "Gcash number (Number na ginamit)",
      placeholder: "0967XXXXXXX",
    },
    maya: {
      label: "Maya number (Number na ginamit)",
      placeholder: "0967XXXXXXX",
    },
    paygo: {
      label: "PAYGO account number",
      placeholder: "Enter PAYGO account number",
    },
    gotyme: {
      label: "Gotyme account number",
      placeholder: "Enter Gotyme account number",
    },
    cliqq: {
      label: "Cliqq account number",
      placeholder: "Enter Cliqq account number",
    },
    bank: {
      label: "Bank account number",
      placeholder: "Enter Bank account number",
    },
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, "");

    if (numericValue === "" || isNaN(parseFloat(numericValue))) {
      setAmount("");
      setError("");
    } else {
      const parsedValue = parseFloat(numericValue);
      setAmount(numericValue);

      if (parsedValue < 49.9) {
        setError("Minimum deposit amount is ₱50.00");
      } else {
        setError("");
      }
    }
  };

  const handleSenderNumberChange = (e) => {
    const value = e.target.value;
    setSenderNumber(value);

    if (value.length !== 11 || !/^\d+$/.test(value)) {
      setSenderError("Sender number must be exactly 11 digits.");
    } else {
      setSenderError("");
    }
  };

  const handleReferenceNumberChange = (e) => {
    const value = e.target.value;
    setReferenceNumber(value);

    if (value.trim() === "") {
      setReferenceError("Reference number cannot be blank or spaces.");
    } else {
      setReferenceError("");
    }
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleDepositClick = async () => {
    if (amount < 49.9) {
      setError("Minimum deposit amount is ₱50.00");
      return;
    }

    try {
      const response = await cashIn(
        parseFloat(amount),
        selectedMethod,
        referenceNumber,
        senderNumber,
        remarks
      );
      console.log("Cash-in response:", response);
      setTransactionId(response.id);
      setShowConfirmationPopup(true);
    } catch (error) {
      console.error("Cash-in error:", error.message);
      setShowErrorPopup(true);
    }
  };

  const handleMethodClick = (method) => {
    setSelectedMethod(method);
  };

  const handleCloseConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
  };

  const handleAmountClick = (presetAmount) => {
    setAmount(`${presetAmount}.00`);
    setSelectedAmount(presetAmount);
    setError("");
  };

  const handleCopyAccountNumber = (accountNumber) => {
    navigator.clipboard
      .writeText(accountNumber)
      .then(() => {
        setShowCopyPopup(true);
      })
      .catch((err) => {
        console.error("Failed to copy account number: ", err);
      });
  };

  useEffect(() => {
    const isValidForm =
      amount >= 49.9 &&
      senderNumber.trim() !== "" &&
      referenceNumber.trim() !== "" &&
      senderError === "" &&
      referenceError === "";
    setIsButtonDisabled(!isValidForm);
  }, [amount, senderNumber, referenceNumber, senderError, referenceError]);

  const { label: numberLabel, placeholder: numberPlaceholder } =
    methodLabels[selectedMethod];

  return (
    <div className="payment-method-container">
      <h4 className="section-label">Deposit Methods</h4>
      <div className="deposit-details-container">
        <div className="withdraw-details-container">
          <h4 className="section-label">Select Payment Method Used</h4>
          <span className="deposit-note">
            NOTE: Piliin ang payment method na ginamit para mag-cash-in.
          </span>
          <div className="payment-method-icons">
            <img
              src={gcash}
              alt="Gcash"
              className={selectedMethod === "gcash" ? "selected" : ""}
              onClick={() => handleMethodClick("gcash")}
            />
            <img
              src={maya}
              alt="Maya"
              className={selectedMethod === "maya" ? "selected" : ""}
              onClick={() => handleMethodClick("maya")}
            />
            <img
              src={paygo}
              alt="PAYGO"
              className={selectedMethod === "paygo" ? "selected" : ""}
              onClick={() => handleMethodClick("paygo")}
            />
            <img
              src={gotyme}
              alt="Gotyme"
              className={selectedMethod === "gotyme" ? "selected" : ""}
              onClick={() => handleMethodClick("gotyme")}
            />
            <img
              src={cliqq}
              alt="Cliqq"
              className={selectedMethod === "cliqq" ? "selected" : ""}
              onClick={() => handleMethodClick("cliqq")}
            />
            <img
              src={bank}
              alt="bank"
              className={selectedMethod === "bank" ? "selected" : ""}
              onClick={() => handleMethodClick("bank")}
            />
          </div>
        </div>
        <div className="deposit-details">
          {loaders
            .filter((loader) => loader.isActive)
            .map((loader) => (
              <div key={loader.id} className="deposit-item">
                <img src={gcash} alt="Payment Method" />
                <div>
                  <p>
                    Account Number: <strong>{loader.accountNo}</strong>
                    <div
                      className="copy-icon-container"
                      onClick={() => handleCopyAccountNumber(loader.accountNo)}
                    >
                      <img src={copyIcon} alt="Copy" className="copy-icon" />
                      <span className="copy-caption">Click here</span>
                      <span className="copy-caption">to copy</span>
                    </div>
                  </p>
                  <p>
                    Account Name: <strong>{loader.name}</strong>
                  </p>
                </div>
              </div>
            ))}
        </div>
        <div className="note">
          <strong>Note:</strong> <strong>Minimum 50 Pesos Cash In!</strong>
        </div>
        <ul className="note-list">
          <li>
            <img src={checkIcon} alt="Check" className="check-icon" />
            Before submitting your deposit request, please double-check the
            reference number and sender's details for quicker approval.
          </li>
          <li>
            <img src={checkIcon} alt="Check" className="check-icon" />
            Refresh this page before initiating any deposit transaction, as our
            GCash/Maya account information may be updated as needed.
          </li>
          <li>
            <img src={checkIcon} alt="Check" className="check-icon" />
            Please ensure that you deposit only to the GCash account listed on
            our official website.
          </li>
        </ul>
      </div>

      <h4 className="section-label">Deposit Form</h4>
      <div className="deposit-form-container">
        <div className="amount-buttons">
          {[100, 200, 500, 1000, 2500, 5000, 7500, 10000].map(
            (presetAmount) => (
              <button
                key={presetAmount}
                className={`amount-button ${
                  selectedAmount === presetAmount ? "selected" : ""
                }`}
                onClick={() => handleAmountClick(presetAmount)}
              >
                {presetAmount}
              </button>
            )
          )}
        </div>
        <div className="input-group">
          <label htmlFor="deposit-amount">Deposit Amount</label>
          <input
            type="text"
            id="deposit-amount"
            placeholder="₱0.00"
            value={amount}
            onChange={handleAmountChange}
          />
          {error && <span className="warning-message">{error}</span>}
        </div>
        <div className="input-group">
          <label htmlFor="sender-number">{numberLabel}</label>
          <input
            type="text"
            id="sender-number"
            placeholder={numberPlaceholder}
            value={senderNumber}
            onChange={handleSenderNumberChange}
          />
          {senderError && <span className="warning-message">{senderError}</span>}
        </div>
        <div className="input-group">
          <label htmlFor="reference-number">Reference Number</label>
          <input
            type="text"
            id="reference-number"
            placeholder="XXXXXXXXXX"
            value={referenceNumber}
            onChange={handleReferenceNumberChange}
          />
          {referenceError && (
            <span className="warning-message">{referenceError}</span>
          )}
        </div>
        <div className="input-group">
          <label htmlFor="note">Note</label>
          <textarea
            id="note"
            placeholder="Enter note"
            value={remarks}
            onChange={handleRemarksChange}
          ></textarea>
        </div>
      </div>

      <GradientButton
        style={{
          marginTop: "16px",
          marginBottom: "16px",
          background: isButtonDisabled
            ? "#5B5B5B"
            : "linear-gradient(90deg, #FFF61F 27%, #7BF342 100%)",
          color: isButtonDisabled ? "#9F9F9F" : undefined,
          cursor: isButtonDisabled ? "not-allowed" : "pointer",
        }}
        text="DEPOSIT NOW"
        onClick={handleDepositClick}
        disabled={isButtonDisabled}
      />
      {showConfirmationPopup && (
        <ConfirmationPopup
          onClose={handleCloseConfirmationPopup}
          transactionId={transactionId}
          amount={amount}
          text="Note: Please wait for 5 minute upon deposit"
        />
      )}
      {showErrorPopup && <ErrorPopup onClose={handleCloseErrorPopup} />}
      <CopyPopup show={showCopyPopup} onClose={() => setShowCopyPopup(false)} />
    </div>
  );
};

export default Deposit;
