// src/components/RaceRunners/RaceRunners.jsx
import React, { useEffect, useState } from "react";
import "./RaceRunners.css";
import useRunnersStore from "../../../../store/useRunnersStore";
import useEventsStore from "../../../../store/useEventStore";
import { useResultsStore } from "../../../../store/useResultStore";
import buttonBackground from "../../../../assets/Race/bet-button-bg.svg";
import BetButton from "./component/BetButton";
import scratchedImage from "../../../../assets/Race/RaceListScratched.png";
import SelectAllCheckbox from "./component/SelectAllCheckbox";
import Winner from "./component/Winner";
import thropy1 from "../../../../assets/Race/thropy-1.svg";
import thropy2 from "../../../../assets/Race/thropy-2.svg";
import thropy3 from "../../../../assets/Race/thropy-3.svg";
import thropy4 from "../../../../assets/Race/thropy-4.svg";
import lose from "../../../../assets/Race/lose.svg";

const RaceRunners = ({
  currentRace,
  selectedBetType,
  onBetInput,
  activeButtons,
  isFirstRaceDisabled,
  selectedRaceRange,
  websocketData,
}) => {
  const { runners, fetchRunners, raceBettingStatus, setRaceBettingStatus } =
    useRunnersStore();
  const { activeEvent } = useEventsStore();
  const { fetchRaceResults, raceResults, racePositions } = useResultsStore();
  const [selectAllState, setSelectAllState] = useState({});

  useEffect(() => {
    if (activeEvent) {
      const race = activeEvent.races.find((r) => r.raceNumber === currentRace);
      if (race) {
        fetchRunners(race.id, race.betting_status);
      }
    }
  }, [activeEvent, currentRace, fetchRunners]);

  useEffect(() => {
    if (websocketData && websocketData.raceId === currentRace?.id) {
      console.log(
        "Updating betting status from websocket:",
        websocketData.betting_status
      );
      setRaceBettingStatus(websocketData.betting_status);
      fetchRunners(websocketData.raceId, websocketData.betting_status);
    }
  }, [websocketData, currentRace, fetchRunners, setRaceBettingStatus]);

  useEffect(() => {
    if (
      ["DD", "XD", "DD+1", "PICK5", "PICK6", "WTA"].includes(selectedBetType)
    ) {
      setSelectAllState({});
    }
  }, [selectedBetType]);

  useEffect(() => {
    if (raceBettingStatus === "open" && currentRace) {
      fetchRaceResults(currentRace);
    } else if (raceBettingStatus === "last call" && currentRace) {
      fetchRaceResults(currentRace);
    } else if (raceBettingStatus === "active" && currentRace) {
      fetchRaceResults(currentRace);
    } else if (raceBettingStatus === "suspended" && currentRace) {
      fetchRaceResults(currentRace);
    } else if (raceBettingStatus === "closed" && currentRace) {
      fetchRaceResults(currentRace);
    }
  }, [raceBettingStatus, currentRace, fetchRaceResults]);

  const firstPlaceWinner =
    raceResults.length > 0 && raceResults[0]?.winners
      ? raceResults[0].winners.split(", ")[0]
      : "No Winner";

  const handleSelectAllChange = (isChecked, label) => {
    setSelectAllState((prevState) => ({
      ...prevState,
      [`${currentRace}-${label}`]: isChecked,
    }));
    runners.forEach((runner) => {
      const betLabel =
        label === "1st" || label === "2nd"
          ? `${label} Place: ${runner.runnerNumber}`
          : `Race ${currentRace}: ${runner.runnerNumber}`;
      onBetInput(betLabel, runner.id, isChecked);
    });
  };

  const handleBetButtonClick = (runner, label, isActive) => {
    const numericPart = runner.runnerNumber.match(/\d+/)[0];
    const coupleEntries = runners.filter((r) =>
      r.runnerNumber.startsWith(numericPart)
    );

    coupleEntries.forEach((coupleRunner) => {
      if (selectedBetType === "FC") {
        onBetInput(
          `${label} Place: ${coupleRunner.runnerNumber}`,
          coupleRunner.id,
          isActive
        );
      } else {
        onBetInput(
          `Race ${currentRace}: ${coupleRunner.runnerNumber}`,
          coupleRunner.id,
          isActive
        );
      }
    });
  };

  const isSecondRaceDisabled = () => {
    if (selectedRaceRange) {
      const raceNumbers = selectedRaceRange.split(/[-&]/).map(Number);

      if (isFirstRaceDisabled) {
        if (
          selectedBetType === "DD+1" ||
          selectedBetType === "PICK5" ||
          selectedBetType === "PICK6" ||
          selectedBetType === "WTA"
        ) {
          if (raceNumbers.includes(currentRace)) {
            return true;
          }
        } else if (
          (selectedBetType === "DD" || selectedBetType === "XD") &&
          raceNumbers.length > 1
        ) {
          const secondRace = raceNumbers[1];
          if (currentRace === secondRace) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getRunnerPosition = (runnerId) => {
    const positionData = racePositions.find((pos) => pos.runnerId === runnerId);
    return positionData ? positionData.position : null;
  };

  const getPositionImage = (position) => {
    switch (position) {
      case 1:
        return thropy1;
      case 2:
        return thropy2;
      case 3:
        return thropy3;
      case 4:
        return thropy4;
      default:
        return lose;
    }
  };

  return (
    <div className="race-horses">
      <div className="select-all-container">
        {raceBettingStatus === "closed" && firstPlaceWinner !== "No Winner" ? (
          <Winner place="1st" number={firstPlaceWinner} />
        ) : selectedBetType === "FC" ? (
          <>
            <SelectAllCheckbox
              isChecked={selectAllState[`${currentRace}-1st`] || false}
              onChange={(isChecked) => handleSelectAllChange(isChecked, "1st")}
            />
            <SelectAllCheckbox
              isChecked={selectAllState[`${currentRace}-2nd`] || false}
              onChange={(isChecked) => handleSelectAllChange(isChecked, "2nd")}
            />
          </>
        ) : (
          <SelectAllCheckbox
            isChecked={selectAllState[`${currentRace}-Race`] || false}
            onChange={(isChecked) => handleSelectAllChange(isChecked, "Race")}
          />
        )}
      </div>
      {runners.map((runner) => {
        const position = getRunnerPosition(runner.id);
        const positionImage = getPositionImage(position);

        return (
          <div key={runner.id} className="race-item">
            <div className="runner-number-container">
              <div className="runner-number-detail">{runner.runnerNumber}</div>
            </div>
            <div className="runner-details">
              <p className="horse-name">{runner.horse.unit.name}</p>
              <p className="jockey-name">Jockey - {runner.jockey.unit.name}</p>
              <p className="trainer-name">
                Trainer - {runner.trainer.unit.name}
              </p>
            </div>
            <div className="bet-buttons">
              <div
                className="button-background"
                style={{ backgroundImage: `url(${buttonBackground})` }}
              >
                {runner.status === "scratch" ? (
                  <img
                    src={scratchedImage}
                    alt="Scratched"
                    className="scratched-image"
                  />
                ) : raceBettingStatus === "closed" ? (
                  <img
                    src={positionImage}
                    alt={`Position ${position}`}
                    className="position-image"
                  />
                ) : selectedBetType === "FC" ? (
                  <>
                    <BetButton
                      label={raceBettingStatus === "closed" ? "Closed" : "1st"}
                      isActive={
                        activeButtons[`1st Place: ${runner.runnerNumber}`]
                      }
                      onClick={(isActive) =>
                        handleBetButtonClick(runner, "1st", isActive)
                      }
                      disabled={raceBettingStatus === "closed"}
                    />
                    <BetButton
                      label={raceBettingStatus === "closed" ? "Closed" : "2nd"}
                      isActive={
                        activeButtons[`2nd Place: ${runner.runnerNumber}`]
                      }
                      onClick={(isActive) =>
                        handleBetButtonClick(runner, "2nd", isActive)
                      }
                      disabled={raceBettingStatus === "closed"}
                    />
                  </>
                ) : (
                  <BetButton
                    label={
                      raceBettingStatus === "closed" ||
                      isFirstRaceDisabled ||
                      isSecondRaceDisabled()
                        ? "Closed"
                        : "Bet"
                    }
                    isActive={
                      activeButtons[
                        `Race ${currentRace}: ${runner.runnerNumber}`
                      ]
                    }
                    onClick={(isActive) =>
                      handleBetButtonClick(runner, "Bet", isActive)
                    }
                    disabled={
                      raceBettingStatus === "closed" ||
                      isFirstRaceDisabled ||
                      isSecondRaceDisabled()
                    }
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RaceRunners;
