// ErrorPopup.js
import React from 'react';
import './Popup.css'; // Create and import appropriate CSS
import errorIcon from '../../assets/Payment/error-logo.svg'
import GradientButton from '../Button/GradientButton';

const ErrorPopup = ({ onClose, errorMessage }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-content">
          <div className="popup-icon">
            <img src={errorIcon} alt="Check" />
          </div>
          <p className="error-note">{errorMessage || "Error occurred, please try again."}</p>
          <GradientButton
            style={{ marginTop: "16px", marginBottom: "16px" }}
            text="PROCEED"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
