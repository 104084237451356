import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";
import useEventsStore from "./useEventStore";

const apiURL = process.env.REACT_APP_API_URL;

export const useResultsStore = create((set, get) => ({
  raceResults: [],
  racePositions: [],
  winRunners: {},
  loading: false,
  error: null,

  fetchRaceResults: async (raceNumber) => {
    const { token } = useAuthStore.getState();
    const { fetchActiveEvent, activeEvent } = useEventsStore.getState();
    set({ loading: true, error: null });

    try {
      // Fetch active event
      await fetchActiveEvent();

      if (!activeEvent) {
        throw new Error("No active event found");
      }

      // Find the race ID for the selected race number
      const race = activeEvent.races.find(r => r.raceNumber === raceNumber);
      if (!race) {
        throw new Error(`Race number ${raceNumber} not found`);
      }

      // Fetch race results
      const resultsResponse = await fetch(`${apiURL}/results/${race.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const results = await resultsResponse.json();

      // Map winners to their details
      const mappedResults = await Promise.all(results.map(async (result) => {
        const runnerResponse = await fetch(`${apiURL}/runners/${result.race_uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const runners = await runnerResponse.json();

        // Store WIN runner number
        const winMeta = result.metadata.find(meta => meta.type === "WIN");
        if (winMeta) {
          const winRunner = runners.find(r => r.id === winMeta.winners[0]);
          if (winRunner) {
            set(state => ({
              winRunners: {
                ...state.winRunners,
                [raceNumber]: winRunner.runnerNumber,
              }
            }));
          }
        }

        return result.metadata.map(meta => ({
          betType: meta.type,
          winners: meta.winners.map(winnerId => {
            const runner = runners.find(r => r.id === winnerId);
            return runner ? runner.runnerNumber : "Unknown";
          }).join(', '),
          dividend: meta.dividend,
        }));
      }));

      // Flatten the array of arrays
      set({ raceResults: mappedResults.flat(), racePositions: results.flatMap(result => result.racePosition) });
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ loading: false });
    }
  },
}));
